/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Privacy Policies generated by Signatu can be integrated in your website or app\nin a number of ways described below."), "\n", React.createElement(_components.h2, {
    id: "sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sdk",
    "aria-label": "sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SDK"), "\n", React.createElement(_components.p, null, "If you require a flexible integration into your site, you should look at the ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk"
  }, "Signatu SDK"), " - either using\n", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/js"
  }, "plain javascript"), " or the ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/policy"
  }, "React library"), "."), "\n", React.createElement(_components.p, null, "In the live example below you can try changing the ", React.createElement(_components.code, null, "link"), " to your own ", React.createElement(_components.code, null, "PermaLink"), " or ", React.createElement(_components.code, null, "ActiveLink"), " and clicking the button:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<PolicyButton link=\"https://cdn.signatu.com/policies/88223ee422c300ff\" />\n")), "\n", React.createElement(_components.h2, {
    id: "direct-link",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#direct-link",
    "aria-label": "direct link permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Direct link"), "\n", React.createElement(_components.p, null, "The policy is hosted on a global Content Delivery Network (CDN), providing\nfast and reliable delivery of the policy to end users. You can link directly\nfrom your site - e.g., ", React.createElement(_components.a, {
    href: "https://cdn.signatu.com/policies/88223ee422c300ff"
  }, "https://cdn.signatu.com/policies/88223ee422c300ff"), "."), "\n", React.createElement(_components.p, null, "There are two types of links:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Activelink"), " - this link will always refer to the ", React.createElement(_components.strong, null, "latest version"), " of the policy and will change when a new version is published."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Permalink"), " - this link will refer to a specific version of the policy, and the content of the link will never change."), "\n"), "\n", React.createElement(_components.p, null, "You can find the links for a specific version on a Policy card by clicking the top right dots and choosing ", React.createElement(_components.strong, null, "History"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 308px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 58.00000000000001%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgElEQVQoz5WQ327TMBSH9wg8AeKCB+AleMntjgu0Z0ADaRItKmMVmlIatKnZXCmJ/xzbjZPYjlsf1LhsFIEQ35V1zu+Tf/ZJCMFah4PfemfdE9773w7ueIuIJ8YYDXBBquuStkpyAQDAOa/rmnPOGKOUwogQwLlQSgGA1novo+3e3K6ff1i8uvz2tWKtkiAl53w2m83n8+l0OplMVquV1toY3XfN/f0DY2yz2SS5P/1Onr27efE+uypZp5UAqUaKosjzvKoqADCNvr4hn+aF4JRxfpCNMVKIt3fri4eyVVIA/CwpGGN1XVPKunZzV5QvX5+dnX8O3jDGD7Wdc9ZaHNzOu94ekf5m8A5kc3r+5ePVaszavt8H9/Jut0PEgBgi/g3Teqk7RByze2KMT/I/iTHlDyTrIMcYUxNEJIQsFossy5bLZZ7nxpgUCCEMw3Akb7dbRHTOZVmWFlrr9QghpCzLEEKap+Gfb34MKaWqqqKUEkKapvm1+eMbj+T/Jck/ACm4pePwMCLnAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Policy Card Menu\"\n        title=\"Policy Card Menu\"\n        src=\"/static/b0490e0c9f4b13b2625a64c2efa9d519/ac66a/policy-history.png\"\n        srcset=\"/static/b0490e0c9f4b13b2625a64c2efa9d519/f8f3a/policy-history.png 200w,\n/static/b0490e0c9f4b13b2625a64c2efa9d519/ac66a/policy-history.png 308w\"\n        sizes=\"(max-width: 308px) 100vw, 308px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 681px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 68.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABLElEQVQoz42SW3bDIAxEvf+1dQH5dp1DEts8JEwsUKANconbND29X+YxGjFWF0JYfsM5BwCI+OtpCKGU0s3zPH1nnufj8fhWORwOSqkfd8Zx1Frfxc45Y4y1VleMMc650+n0Xun7/nw+O+fsDmMMANzFt9stV0ol5yw75QvZeSbnfBev6+q97/t+GAbvvQjKn4hfJ4VjjJJNSqn8g03MzCklIrpcLtba/dn+uy2JSKLe2k4pxRglBgDw3rsKIraoEFH0KaV1XR/inDMRaa0BIL+gNSxOm5iZieh6vSqlpmkSZwAQc/kxy7K0FImoLTspLIFJP68SknS4sgUmshDCMAxKKZk+RPTeM/O+2xACIhpjxnF8OLfCzBx3tPl5fvw2JK2Z5xniF8j9z/H8AK3qLhvH1QdEAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Policy Links\"\n        title=\"Policy Links\"\n        src=\"/static/bc0e4be0831b434b1cd4084b53c51966/ad007/policy-links.png\"\n        srcset=\"/static/bc0e4be0831b434b1cd4084b53c51966/f8f3a/policy-links.png 200w,\n/static/bc0e4be0831b434b1cd4084b53c51966/6a8a8/policy-links.png 400w,\n/static/bc0e4be0831b434b1cd4084b53c51966/ad007/policy-links.png 681w\"\n        sizes=\"(max-width: 681px) 100vw, 681px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "embedded-iframe",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#embedded-iframe",
    "aria-label": "embedded iframe permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Embedded IFrame"), "\n", React.createElement(_components.p, null, "You can embed an IFrame with the Signatu Policy into your site. Note that you may need to ", React.createElement(_components.a, {
    href: "/docs/api/v1/security#content-security-policy"
  }, "allow this in your website security settings"), "."), "\n", React.createElement(_components.p, null, "Simply use either the ", React.createElement(_components.code, null, "ActiveLink"), " or ", React.createElement(_components.code, null, "PermaLink"), " in an IFrame:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<iframe src=\"https://cdn.signatu.com/policies/88223ee422c300ff\" />\n")), "\n", React.createElement(_components.h2, {
    id: "download",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#download",
    "aria-label": "download permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Download"), "\n", React.createElement(_components.p, null, "From the ", React.createElement(_components.strong, null, "History"), " view described above you can download the Policy in PDF, Markdown and JSON formats."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
